import { Typography } from '@material-tailwind/react';
import React, { useState } from 'react';

export default function Downloads() {
    const [startDate, setStartDate] = useState(() => getCurrentMonthFirstDay());
    const [endDate, setEndDate] = useState(() => formatDate(0));
    const [reportType, setReportType] = useState("");

    function getCurrentMonthFirstDay() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}-01`;
    }

    function formatDate(daysAdd = 0) {
        const date = new Date();
        date.setDate(date.getDate() + daysAdd);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const downloadReport = async () => {
        alert("TODO");
    };

    return (
        <div>
            <div className="hidden md:block relative flex flex-col min-w-0 break-words w-full pt-3 mb-6 shadow-lg rounded-lg bg-blueGray-100 border border-blue-gray-100 border-1">
                <div className="flex flex-wrap">
                    <div className="w-full lg:w-3/12 px-4">
                        <div className="h-10 flex justify-between sm:justify-start">
                            <Typography className="mt-2">
                                Start:
                            </Typography>
                            <div className="flex flex-row">
                                <input type="date" className="border-0 ml-2 mr-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    value={startDate} onChange={(event) => setStartDate(event.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-3/12 px-4">
                        <div className="h-10 flex justify-between sm:justify-start">
                            <Typography className="mt-2">
                                End:
                            </Typography>
                            <div className="flex flex-row">
                                <input type="date" className="border-0  ml-2 mr-2  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    value={endDate} onChange={(event) => setEndDate(event.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-3/12 px-4">
                        <div className="h-10 flex justify-between sm:justify-start">
                            <Typography className="mt-2">
                                Type:
                            </Typography>
                            <select label="reportType" color="blue" className="ml-4 bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                name="reportType" id="reportType"
                                onChange={(event) => setReportType(event.target.value)}
                                value={reportType}>
                                <option key="" value="">Select</option>
                                <option key="Cards" value="Cards">Card Report</option>
                                <option key="GST" value="GST">GST Invoices</option>
                                <option key="Expense" value="Expense">Expense Report</option>
                                <option key="Occupancy" value="Occupancy">Occupancy Report</option>
                            </select>
                        </div>
                    </div>
                    <div className="w-full lg:w-3/12 px-4">
                        <div className=" flex justify-end">
                            <button className="bg-pink-500 text-white hover:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150"
                                type="button" onClick={downloadReport}>
                                Download
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block md:hidden text-center">
                <p class="text-lg font-bold text-red-600">Please use a desktop or laptop to access the downloads.</p>
            </div>
        </div >
    );
}