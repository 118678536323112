import { useAuth0 } from "@auth0/auth0-react";
import { createContext, useContext, useEffect, useState } from "react";
import { useApi } from "../hooks/useApi";
import { ClockLoader } from 'react-spinners';


export const UserContext = createContext();

export const useUserContext = () => {
    return useContext(UserContext);
}

export function UserContextProvider(props) {
    const [isAdmin, setIsAdmin] = useState(false);
    const [assignments, setAssignments] = useState({ roles: ["MANAGER"], hotels: [] });
    const { getHotels } = useApi();
    const [defaultHotel, setDefaultHotel] = useState("");
    const {
        isAuthenticated,
        isLoading,
        user,
        error,
        getIdTokenClaims,
        loginWithRedirect: login,
        logout } = useAuth0();


    useEffect(() => {
        if (isAuthenticated && user) {
            const userRoles = user.app_metadata.roles;
            setIsAdmin(userRoles.includes("ADMIN"));
            setAssignments(prevState => ({
                ...prevState,
                roles: userRoles
            }));
        }
    }, [isAuthenticated, user]);


    useEffect(() => {
        const handleUserInfo = async () => {
            if (!isLoading && user && isAuthenticated) {
                try {
                    const token = await fetchAccessToken();
                    const hotels = await getHotels(token);
                    setAssignments(prevState => ({
                        ...prevState,
                        hotels: hotels
                    }));
                    if (hotels.length > 0) {
                        const defaultHotelLocal = localStorage.getItem('defaultHotel');
                        if (defaultHotelLocal && hotels.find(hotel => hotel.id === defaultHotelLocal)) {
                            setDefaultHotel(defaultHotelLocal);
                        } else {
                            setDefaultHotel(hotels[0].id);
                        }
                    }
                } catch (ex) {
                }
            }
        };

        handleUserInfo();
    }, [isLoading, user, isAuthenticated]);

    useEffect(() => {
        if (defaultHotel) {
            localStorage.setItem('defaultHotel', defaultHotel);
        }
    }, [defaultHotel]);



    const fetchAccessToken = async () => {
        const tokenClaims = await getIdTokenClaims();
        const { __raw: idToken } = tokenClaims;
        return idToken;
    };


    return <UserContext.Provider value={{ logout, login, fetchAccessToken, setDefaultHotel, defaultHotel, isLoading, isAuthenticated, user, isAdmin, assignments, error }}>
        {isLoading && <ClockLoader color="#2be60d" />}
        {!isLoading && props.children}
    </UserContext.Provider>
}