import React from 'react';
import { Tooltip, Typography } from '@material-tailwind/react';
import { MdOutlineBedroomChild } from "react-icons/md";


export default function RoomBookingCard({ roomDetails }) {
    return (
        <Tooltip
            animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
            }}
            content={
                <table className="min-w-full divide-y text-blue-gray-500">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Room#</th>
                            <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Check In</th>
                            <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Check Out</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y ">
                        {roomDetails.map((roomDetail, index) => (
                            <tr key={index}>
                                <td className="px-3 py-3 whitespace-nowrap">{roomDetail.roomNo}</td>
                                <td className="px-3 py-3 whitespace-nowrap">{roomDetail.checkInDateTime}</td>
                                <td className="px-3 py-3 whitespace-nowrap">{roomDetail.checkOutDateTime}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
        >
            <Typography
                variant="small"
                color="blue-gray"
                className="font-normal flex flex-col cursor-pointer"
            >
                {roomDetails.map(roomDetail => (
                    <div className="flex flex-row text-blue-gray-500 mt-2" key={roomDetail.roomNo}>
                        <MdOutlineBedroomChild className="h-6 w-6" />
                        <Typography key={roomDetail.roomNo} variant="small" className="pl-2 pt-0 underline">{roomDetail.roomNo}</Typography>
                    </div>
                ))}
            </Typography>
        </Tooltip>
    )
}
