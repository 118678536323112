import { Dialog, DialogHeader, DialogBody, DialogFooter, Button, Tooltip, IconButton } from '@material-tailwind/react';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClockLoader } from 'react-spinners';
import { useUserContext } from '../../context';
import { useApi } from '../../hooks/useApi';
import { HiReceiptTax } from "react-icons/hi";
import { useNavigate } from 'react-router-dom';

export default function CardDetails() {
    const { cardNo } = useParams();
    const { fetchAccessToken, defaultHotel, assignments } = useUserContext();
    const [isLoading, setIsLoading] = useState(false);
    const [cardData, setCardData] = useState({});
    const [guestMobile, setGuestMobile] = useState("");
    const { getCard, searchGuest, getTaxInvoiceByCardNo, saveTaxInvoice, getNextTaxInvoiceNo, downloadTaxInvoice } = useApi();
    const [hotelDetails, setHotelDetails] = useState({});
    const [companyAddress, setCompanyAddress] = useState("");
    const [companyGST, setCompanyGST] = useState("");
    const [isGSTInvoice, setIsGSTInvoice] = useState(false);
    const [gstAmount, setGstAmount] = useState(0);
    const [showAmountPopup, setShowAmountPopup] = useState(false);
    const [taxInvoiceNo, setTaxInvoiceNo] = useState(() => {
        return getFinancialYear() + "/XXX";
    });
    const [gstDate, setGstDate] = useState(Date.now());
    const navigate = useNavigate();

    function getFinancialYear() {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        let startYear, endYear;

        if (month >= 3) {
            startYear = year;
            endYear = year + 1;
        } else {
            startYear = year - 1;
            endYear = year;
        }
        startYear = startYear.toString().slice(-2);
        endYear = endYear.toString().slice(-2);

        return `${startYear}-${endYear}`;
    }

    useEffect(() => {
        let getCardInternal = async (hotelId, cardNo) => {
            setIsLoading(true);
            try {
                const token = await fetchAccessToken();
                const card = await getCard(hotelId, cardNo, token);
                const taxInvoice = await getTaxInvoiceByCardNo(hotelId, cardNo, token);
                if (card) {
                    if (taxInvoice) {
                        setIsGSTInvoice(true);
                        card.amountReceived = taxInvoice.gstAmount;
                        setTaxInvoiceNo(taxInvoice.invoiceNo);
                        setGstDate(taxInvoice.createdOn);
                    } else {
                        const td = await getNextTaxInvoiceNo(hotelId, token);
                        if (td) {
                            let hotel = assignments?.hotels.find(hotel => hotel.id.toLowerCase() === hotelId.toLowerCase());
                            setTaxInvoiceNo(hotel.invoicePrefix + "/" + getFinancialYear() + "/" + String(td.nextTaxInvoiceNo).padStart(3, '0'));
                        }
                    }
                    setCardData(card);
                    setGuestMobile(card.guestMobile);
                    setGstAmount(card.amountReceived);
                } else {
                    alert("Card No doesn't belongs to the hotel.");
                    navigate("/manager/cards");
                }
            } catch (ex) {
                alert("Something went wrong.");
            } finally {
                setIsLoading(false);
            }
        }
        if (cardNo && defaultHotel) {
            getCardInternal(defaultHotel, cardNo);
        }
    }, [cardNo, defaultHotel]);

    useEffect(() => {
        let getGuestDetails = async (defaultHotel, guestMobile) => {
            setIsLoading(true);
            try {
                const token = await fetchAccessToken();
                const guest = await searchGuest(defaultHotel, guestMobile, token);
                if (guest) {
                    setCompanyGST(guest.companyGST);
                    setCompanyAddress(guest.companyDetails);
                    setCardData({ ...cardData, guestName: guest.name })
                }
            } catch (ex) {
            } finally {
                setIsLoading(false);
            }
        }
        if (defaultHotel && guestMobile) {
            getGuestDetails(defaultHotel, guestMobile);
        }
    }, [guestMobile]);

    useEffect(() => {
        if (defaultHotel) {
            let hotel = assignments?.hotels.find(hotel => hotel.id.toLowerCase() === defaultHotel.toLowerCase());
            setHotelDetails(hotel);
        }
    }, [defaultHotel]);


    function formatDateFromEpoch(epoch) {
        if (!epoch)
            return "";
        const date = new Date(epoch);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }

    const generateGstInvoice = async () => {
        if (cardNo && defaultHotel) {
            const result = window.confirm("Do you want to convert Card to GST Invoice?");
            if (result) {
                try {
                    setIsLoading(true);
                    const token = await fetchAccessToken();
                    let taxInvoice = {
                        hotelId: defaultHotel,
                        cardNo: cardNo,
                        invoiceNo: `${taxInvoiceNo}`,
                        gstAmount: gstAmount,
                        createdOn: Date.now(),
                        guestName: cardData.guestName,
                        guestMobile: cardData.guestMobile,
                        companyGST: companyGST,
                        companyDetails: companyAddress
                    };
                    const data = await saveTaxInvoice(defaultHotel, taxInvoice, token);
                    if (data) {
                        setIsGSTInvoice(true);
                    }
                } catch (ex) {

                }
                finally {
                    setIsLoading(false);
                }
            }
        }
    }
    const printInvoice = async () => {
        if (!isGSTInvoice) {
            alert("Please convert to GST before print.");
            return;
        }
        if (cardNo && defaultHotel && isGSTInvoice) {
            try {
                setIsLoading(true);
                const token = await fetchAccessToken();
                await downloadTaxInvoice(defaultHotel, cardNo, token);
            } catch (ex) {
                alert("Something went wrong.");
            } finally {
                setIsLoading(false);
            }
        }
    }

    function getBasePrice(totalAmount) {
        let basePrice = Math.ceil(totalAmount / 1.12);
        let cgst = Math.ceil((totalAmount / 1.12) * 0.06);
        let sgst = Math.ceil((totalAmount / 1.12) * 0.06);
        return basePrice - (basePrice + cgst + sgst - totalAmount);
    }

    return (
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg bg-blueGray-100 border border-blue-gray-100 border-1 ">
            <div className="rounded-lg bg-white p-8 text-xs">
                <div style={{ height: '180px' }}>
                    &nbsp;
                </div>
                <div className="text-center mb-4">
                    <p className="text-gray-900 text-xl font-semibold"><u>TAX INVOICE</u></p>
                </div>
                <hr />
                <div className="mb-6">
                    <table className="w-full text-gray-900 border-collapse">
                        <tbody>
                            <tr>
                                <td className="border py-2 px-4 w-1/4"><span className="font-semibold">Invoice No</span></td>
                                <td className="border py-2 px-4 w-1/4">{taxInvoiceNo}</td>
                                <td className="border py-2 px-4 w-1/4"><span className="font-semibold">Date</span></td>
                                <td className="border py-2 px-4 w-1/4">{formatDateFromEpoch(gstDate)}</td>
                            </tr>
                            <tr>
                                <td className="border py-2 px-4"><span className="font-semibold">Customer Details</span></td>
                                <td className="border py-2 px-4 uppercase">{cardData.guestName} <br /> {cardData.guestMobile}</td>
                                <td className="border py-2 px-4" rowSpan={2}><span className="font-semibold">Company Details</span></td>
                                <td className="border py-2 px-4 uppercase" rowSpan={2}>{companyAddress}</td>
                            </tr>
                            <tr>
                                <td className="border py-2 px-4"><span className="font-semibold">Customer GST No</span></td>
                                <td className="border py-2 px-4 uppercase">{companyGST}</td>
                            </tr>
                            <tr>
                                <td className="border py-2 px-4" colSpan={4}><span className="font-semibold">Room Details</span></td>
                            </tr>
                            <tr>
                                <td className="border" colSpan={4}>
                                    <table className="w-full divide-y divide-gray-200 bg-white overflow-hidden">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th className="border px-6 py-3 text-left text-xs font-medium text-gray-900 uppercase">Room No</th>
                                                <th className="border px-6 py-3 text-left text-xs font-medium text-gray-900 uppercase">Category</th>
                                                <th className="border px-6 py-3 text-left text-xs font-medium text-gray-900 uppercase">In Date & Time</th>
                                                <th className="border px-6 py-3 text-left text-xs font-medium text-gray-900 uppercase">Out Date & Time</th>
                                                <th className="border px-6 py-3 text-left text-xs font-medium text-gray-900 uppercase">Days</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {cardData?.roomDetails?.map((roomDetail, index) => (
                                                <tr key={index}>
                                                    <td className="border px-6 py-4 text-gray-900">{roomDetail.roomNo}</td>
                                                    <td className="border px-6 py-4 text-gray-900">{roomDetail.category}</td>
                                                    <td className="border px-6 py-4 text-gray-900">{roomDetail.checkInDateTime}</td>
                                                    <td className="border px-6 py-4 text-gray-900">{roomDetail.checkOutDateTime}</td>
                                                    <td className="border px-6 py-4 text-gray-900">{roomDetail.totalDays}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td className="border py-2 px-4" colSpan={4}><span className="font-semibold">Price Details</span></td>
                            </tr>
                            <tr>
                                <td className="border py-2 px-4" colSpan={2}>
                                    <pre>{hotelDetails.bankDetails}</pre>
                                </td>
                                <td className="border py-2 px-4" colSpan={2}>
                                    <table className="w-full divide-y divide-gray-200 bg-white overflow-hidden rounded-lg">
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            <tr>
                                                <td className="text-xs border px-6 py-4 text-gray-900">Base Price</td>
                                                <td className="border px-6 py-4 text-gray-900">{getBasePrice(gstAmount)}.00</td>
                                            </tr>
                                            <tr>
                                                <td className="text-xs border px-6 py-4 text-gray-900">CGST 6%</td>
                                                <td className="border px-6 py-4 text-gray-900">{Math.ceil((gstAmount / 1.12) * 0.06)}.00</td>
                                            </tr>
                                            <tr>
                                                <td className="text-xs border px-6 py-4 text-gray-900">SGST 6%</td>
                                                <td className="border px-6 py-4 text-gray-900">{Math.ceil((gstAmount / 1.12) * 0.06)}.00</td>
                                            </tr>
                                            <tr>
                                                <td className="text-xs border px-6 py-4 text-gray-900">Total Amount</td>
                                                <td className="border px-6 py-4 text-gray-900">{gstAmount}.00</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="mt-20 text-center">
                    <div className="flex">
                        <div className="w-1/2  text-black-900 p-4">
                            <p>Customer Sign</p>
                        </div>
                        <div className="w-1/2 text-black-900 p-4">
                            <p>Authorised Signature</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-center m-2">
                {isGSTInvoice &&
                    <Tooltip content="GST Invoice generated">
                        <IconButton variant="text">
                            <HiReceiptTax className="h-10 w-10" color="green" />
                        </IconButton>
                    </Tooltip>}
                {!isGSTInvoice &&
                    <Tooltip content="GST Invoice not generated">
                        <IconButton variant="text">
                            <HiReceiptTax className="h-10 w-10" color="grey" />
                        </IconButton>
                    </Tooltip>}
                {!isGSTInvoice && <button className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 ml-4 mr-4 rounded"
                    onClick={() => setShowAmountPopup(!showAmountPopup)}>Modify GST Amount</button>
                }
                {!isGSTInvoice && <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-2 rounded"
                    onClick={generateGstInvoice}>Convert to GST</button>
                }

                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-2 rounded"
                    onClick={printInvoice}>Print</button>
            </div>
            <Dialog open={showAmountPopup} >
                <DialogHeader className="flex items-center h-full">
                    <DialogHeader>Invoice Amount</DialogHeader>
                </DialogHeader>
                <DialogBody>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="flex flex-col">
                            <label for="gstAmount" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Amount</label>
                            <input type="number" id="gstAmount" name="gstAmount" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                onChange={(e) => setGstAmount(parseInt(e.target.value, 10))}
                                value={gstAmount} />
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={() => setShowAmountPopup(false)}
                        className="mr-1">
                        <span>Ok</span>
                    </Button>
                </DialogFooter>
            </Dialog>
            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>
        </div >
    )
}
