import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader
} from "@material-tailwind/react";
import { useFormik } from "formik";
import React from 'react';
import * as Yup from 'yup';

export default function RoomCategoryPopup({ initialValues, isOpen, onClose, onSubmit }) {

    let roomTypeFormik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            category: Yup.string().required('Category is required'),
            price: Yup.number().typeError('Price must be a number').positive('Price must be a positive number').required('Price is required'),
            'capacity_adults': Yup.number().required('Adults capacity is required').positive('Adults capacity must be a positive number').integer('Adults capacity must be an integer'),
            'capacity_kids': Yup.number().required('Kids capacity is required').integer('Kids capacity must be an integer'),
            extraBedCharges: Yup.number().typeError('Extra bed charges must be a number').required('Extra bed charges is required'),
            gst: Yup.number().typeError('GST% must be a number').positive('GST% must be a positive number').required('GST% is required')
        }),
        onSubmit: async (values, actions) => {
            await onSubmit(values);
        }
    });

    let handleSubmitInternal = () => {
        roomTypeFormik.handleSubmit();
    };

    let onCloseInternal = () => {
        roomTypeFormik.resetForm();
        onClose();
    };


    return (
        <Dialog open={isOpen}>
            <form>
                <DialogHeader>Add Category</DialogHeader>
                <DialogBody>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="flex flex-col">
                            <label for="category" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Category</label>
                            <input type="text" id="category" name="category" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                onChange={roomTypeFormik.handleChange} onBlur={roomTypeFormik.handleBlur}
                                value={roomTypeFormik.values.category} />
                            {roomTypeFormik.touched.category && roomTypeFormik.errors.category ? (
                                <div className="text-red-500">{roomTypeFormik.errors.category}</div>
                            ) : null}
                        </div>
                        <div className="flex flex-col">
                            {" "}
                        </div>
                        <div className="flex flex-col">
                            <label for="price" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Price</label>
                            <input type="number" id="price" name="price" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                onChange={roomTypeFormik.handleChange} onBlur={roomTypeFormik.handleBlur}
                                value={roomTypeFormik.values.price} />
                            {roomTypeFormik.touched.price && roomTypeFormik.errors.price ? (
                                <div className="text-red-500">{roomTypeFormik.errors.price}</div>
                            ) : null}
                        </div>
                        <div className="flex flex-col">
                            <label for="gst" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">GST %</label>
                            <input type="number" id="gst" name="gst" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                onChange={roomTypeFormik.handleChange} onBlur={roomTypeFormik.handleBlur}
                                value={roomTypeFormik.values.gst} />
                            {roomTypeFormik.touched.gst && roomTypeFormik.errors.gst ? (
                                <div className="text-red-500">{roomTypeFormik.errors.gst}</div>
                            ) : null}
                        </div>
                        <div className="flex flex-col">
                            <label for="adults" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Adults</label>
                            <input type="number" id="capacity_adults" name="capacity_adults" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                onChange={roomTypeFormik.handleChange} onBlur={roomTypeFormik.handleBlur}
                                value={roomTypeFormik.values.capacity_adults} />
                            {roomTypeFormik.touched.capacity_adults && roomTypeFormik.errors.capacity_adults ? (
                                <div className="text-red-500">{roomTypeFormik.errors.capacity_adults}</div>
                            ) : null}
                        </div>
                        <div className="flex flex-col">
                            <label for="kids" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Kids</label>
                            <input type="number" id="capacity_kids" name="capacity_kids" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                onChange={roomTypeFormik.handleChange} onBlur={roomTypeFormik.handleBlur}
                                value={roomTypeFormik.values.capacity_kids} />
                            {roomTypeFormik.touched.capacity_kids && roomTypeFormik.errors.capacity_kids ? (
                                <div className="text-red-500">{roomTypeFormik.errors.capacity_kids}</div>
                            ) : null}
                        </div>
                        <div className="flex flex-col">
                            <label for="extraBedCharges" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Extra Bed Charges</label>
                            <input type="number" id="extraBedCharges" name="extraBedCharges" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" onChange={roomTypeFormik.handleChange} onBlur={roomTypeFormik.handleBlur}
                                value={roomTypeFormik.values.extraBedCharges} />
                            {roomTypeFormik.touched.extraBedCharges && roomTypeFormik.errors.extraBedCharges ? (
                                <div className="text-red-500">{roomTypeFormik.errors.extraBedCharges}</div>
                            ) : null}
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={onCloseInternal}
                        className="mr-1"
                    >
                        <span>Cancel</span>
                    </Button>
                    <Button variant="gradient" color="green" onClick={handleSubmitInternal}>
                        <span>Save</span>
                    </Button>
                </DialogFooter>
            </form>
        </Dialog>
    )
}
