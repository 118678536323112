import { Dialog, DialogBody, DialogFooter, DialogHeader, Button } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';

export default function RoomShift({ isOpen, onClose, onSubmit, roomShiftRequest }) {

    const [newRoomNo, setNewRoomNo] = useState("");
    const [newCategory, setNewCategory] = useState("");
    const [newPaymentDetails, setNewPaymentDetails] = useState([]);

    useEffect(() => {
        let priceDetails = [];
        if (newRoomNo && newCategory) {
            const rc = getRoomDetailsByCategory(newCategory);
            const previousRoom = getPreviousRoom(roomShiftRequest.roomNoToChange)
            const priceDetail = {};
            priceDetail.roomNo = newRoomNo;
            priceDetail.category = rc.category;
            priceDetail.fare = previousRoom.totalDays * rc.price;
            priceDetail.extraBedCost = previousRoom.totalDays * previousRoom.extraBeds * rc.extraBedCharges;
            priceDetail.gst = ((previousRoom.totalDays * rc.price) + priceDetail.extraBedCost) * (rc.gst / 100);
            priceDetail.totalCost = priceDetail.fare + priceDetail.extraBedCost + priceDetail.gst;
            priceDetails.push(priceDetail);
        }
        setNewPaymentDetails(priceDetails);

    }, [newRoomNo, newCategory]);

    function getRoomDetailsByCategory(category) {
        const rc = roomShiftRequest.roomTypes.find(rc => rc.category === category);
        return rc;
    }

    function getPreviousRoom(roomNo) {
        return roomShiftRequest.card.roomDetails.find(rd => rd.roomNo === roomNo);
    }

    function getPreviousCheckIn(roomNo) {
        return roomShiftRequest.checkIns.find(ck => ck.roomNo === roomNo);
    }

    const handleSubmitInternal = async () => {
        if (newRoomNo && newCategory) {

            const previousCheckIn = getPreviousCheckIn(roomShiftRequest.roomNoToChange);
            const newCheckIn = JSON.parse(JSON.stringify(previousCheckIn));
            newCheckIn.roomNo = newRoomNo;
            const obsoleteCheckIns = [];
            obsoleteCheckIns.push(previousCheckIn);
            roomShiftRequest.checkIns = [];
            roomShiftRequest.checkIns.push(newCheckIn);
            let totalAmount = 0;
            roomShiftRequest.card.roomDetails.forEach(rd => {
                if (rd.roomNo === roomShiftRequest.roomNoToChange) {
                    const newRC = getRoomDetailsByCategory(newCategory);
                    rd.roomNo = newRoomNo;
                    rd.category = newCategory;
                    rd.fare = rd.totalDays * newRC.price;
                    rd.extraBedCost = rd.totalDays * rd.extraBeds * newRC.extraBedCharges;
                    rd.gst = ((rd.totalDays * newRC.price) + rd.extraBedCost) * (newRC.gst / 100);
                    rd.totalAmount = rd.fare + rd.extraBedCost + rd.gst;
                }
                totalAmount += rd.totalAmount;
            });
            roomShiftRequest.card.totalAmount = totalAmount;
            roomShiftRequest.card.amountDue = roomShiftRequest.card.totalAmount - roomShiftRequest.card.amountReceived - roomShiftRequest.card.discount;
            await onSubmit(roomShiftRequest.card, roomShiftRequest.checkIns, obsoleteCheckIns);
        }
    };
    return (
        <Dialog open={isOpen}>
            <DialogHeader>Shift room from {roomShiftRequest.roomNoToChange}</DialogHeader>
            <DialogBody>
                <div className="w-full lg:w-12/12 px-4 rounded overflow-scroll">
                    <div className="relative w-full mb-3">
                        <div className="flex flex-wrap border border-gray-300 p-2" >
                            <div className="w-50 mr-3 mt-3 mb-3">
                                <label htmlFor="newRoomNo" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">New Room<span className="text-red-500">*</span></label>
                                <select label="newRoomNo" color="blue" className="bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                    name="newRoomNo" id="newRoomNo"
                                    onChange={(event) => setNewRoomNo(event.target.value)}
                                    value={newRoomNo}>
                                    <option value="">Select</option>
                                    {roomShiftRequest.availableRooms.map((room, i) => (
                                        <option key={i} value={room}>{room}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="w-50 mr-3 mt-3 mb-3">
                                <label htmlFor="newCategory" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">New Category Room<span className="text-red-500">*</span></label>
                                <select label="newCategory" color="blue" className="bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                    name="newCategory" id="newCategory"
                                    onChange={(event) => setNewCategory(event.target.value)}
                                    value={newCategory}>
                                    <option value="">Select</option>
                                    {roomShiftRequest.roomTypes.map((category, i) => (
                                        <option key={i} value={category.category}>{category.category}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="relative w-full mb-3">
                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                            Price Details
                        </label>
                        <table className="min-w-full divide-y divide-gray-200 bg-white shadow overflow-hidden rounded-lg">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Room No</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Room Type</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Room Fare</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Extra Bed Cost</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">GST (12%)</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Cost</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {newPaymentDetails.map((priceDetail, i) => <tr>
                                    <td className="px-6 py-4 whitespace-nowrap text-gray-700">{priceDetail.roomNo}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-gray-700">{priceDetail.category}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-gray-700">{priceDetail.fare}.00</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-gray-700">{priceDetail.extraBedCost}.00</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-gray-700">{priceDetail.gst}.00</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-gray-700">{priceDetail.totalCost}.00</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="text"
                    color="red"
                    onClick={onClose}
                    className="mr-1"
                >
                    <span>Cancel</span>
                </Button>
                <Button variant="gradient" color="green" onClick={handleSubmitInternal}>
                    <span>Save</span>
                </Button>
            </DialogFooter>
        </Dialog>
    );
}
